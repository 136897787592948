<template>
  <div
    class="widgetContainer bg-white widgetContainer--scrollable pullFundsAmount">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          Instant Pay
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form class="wise-form" ref="pullFundsAmount" :model="formData">
        <el-form-item
          prop="source"
          class="is-no-asterisk"
          :label="$t('pay_section_source')">
          <el-input :value="getSelectedAccount.label" />
        </el-form-item>

        <el-form-item
          class="is-no-asterisk destination_card"
          :label="$t('pay_section_destination')">
          <account-card :account="shifl_instant_pay_selected_account" />
        </el-form-item>

        <el-form-item
          class="is-no-asterisk"
          prop="amount"
          :label="$t('pay_row_amount')">
          <Amount :masked="true" v-model="formData.amount" />
        </el-form-item>

        <el-form-item
          prop="description"
          class="is-no-asterisk"
          :label="$t('description')">
          <el-input v-model="formData.description" />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="isFormValid"
        @click="sendTransfer"
        class="el-button__brand brand width-100">
        {{ $t('transfer') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Amount from '@/components/Amount';
import AccountCard from '@m/send/views/ShiflInstantPay/AccountCard';
import {contactService} from '@/services/solidfi';

export default {
  name: 'IntrabankTransfer',
  components: {
    AccountCard,
    Amount
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('account', ['getAllAccount']),
    ...mapGetters('user',['getPersonDetails']),
    ...mapGetters('send',['shifl_instant_pay_person_accounts','get_shifl_instant_pay_person','shifl_instant_pay_selected_account']),
    isFormValid() {
      return !(
        !!this.getSelectedAccount &&
          parseFloat(this.formData.amount) > 0 &&
          !!this.formData.description
      );
    },
    destinationAccounts() {
      return this.shifl_instant_pay_selected_account
    },
    setAccountSeletionPlaceholder() {
      return this.destinationAccounts.length ? 'Select' : this.$t('no_accounts_found');
    }
  },
  data() {
    return {
      formData: {
        selectedBank: null,
        amount: '',
        description: ''
      }
    };
  },
  methods: {
    ...mapActions('contact', ['createContact']),
    ...mapActions('pay', ['intrabankPay']),
    sendTransfer() {
      const loader = this.showLoader();
      const destinationAccount = this.shifl_instant_pay_selected_account

      this.createContact({
        accountId: this.getSelectedAccount.id,
        name: destinationAccount.accountNumber,
        email: destinationAccount.id + '@example.com',
        intrabank: {
          accountNumber: destinationAccount.accountNumber
        }
      }).then(async contactResult => {
        await this.sendPay(contactResult)
        await this.deleteContact(contactResult)
      }).catch(e => {
        if (e.response.data.message == 'Contact already present'){
          const message = e.response.data.sysMessage;
          const query = message.substring(message.indexOf('(')+1,message.lastIndexOf(')'));
          return contactService.contacts(this.getSelectedAccount.id,{query})
            .then(result => {
              const contact = result.data.data?.[0];
              return this.sendPay(contact);
            })
            .catch(e => this.apiErrorMessage(e))
        }
      }).finally(() => loader.close())
    },
    sendPay(contactResult){
      return this.intrabankPay({
        accountId: this.getSelectedAccount.id,
        contactId: contactResult.id,
        amount: this.formData.amount,
        description: this.formData.description
      }).then(() => {
        this.$emit('goTo', 'IntrabankFundOutSuccess');
      }).catch(e => this.apiErrorMessage(e))
    },
    async deleteContact(contact){
      await contactService.deleteContact(contact.id)
    }
  }
};
</script>

<style lang="scss">
.pullFundsAmount {
  position: relative;

  .el-button__brand {
    text-transform: capitalize;
  }

  .el-form-item.destination_card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
