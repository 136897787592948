<template>
  <el-card class="mb-1">
    <ul>
      <li>Type: {{ accountType }}</li>
      <li v-if="account.businessId">
        <el-skeleton v-if="loading">
          <el-skeleton-item variant="p" />
        </el-skeleton>
        <span v-else>Label: {{ account.label }}</span>
      </li>
      <li>Account Number: {{ account.accountNumber }}</li>
    </ul>
  </el-card>
</template>

<script>

export default {
  name: 'AccountCard',
  props: {
    account: {
      required: true,
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
      loading: false,
    }
  },
  created() {
  },
  computed:{
    accountType(){
      return this.account.type === 'businessChecking' ? 'Business Account' : 'Personal Account'
    }
  },
  methods:{
  }
}
</script>

<style scoped>
  .el-card{
    cursor: pointer;
  }
</style>